@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

#root {
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
    padding-top: 60px;
    background: linear-gradient(135deg, #dcc3c3 0%, #d9dee5 50%, #b0c2e0 100%);
    font-family: 'Roboto', sans-serif;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 600vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

/* App.css */

/* Landing Page */

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin-top: 0px;
    background: linear-gradient(135deg, #dcc3c3 0%, #d9dee5 50%, #b0c2e0 100%);
}

.landing-page .image-container {
    margin-top: -300px; /* Adjust this value as needed to position it higher */
    width: 300px; /* Adjust this based on your image's natural dimensions and how large you want it to appear */
    height: 200px; /* Adjust this based on your image's natural dimensions and how large you want it to appear */
    background: url('./assets/altSolBackground.png') no-repeat center;
    background-size: contain; /* This will make sure your image fits within the dimensions specified without getting cropped or distorted */
    margin-bottom: 0px; /* Gives some space between the image and the text */
}

.landing-page h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #4c5262;
}

.landing-page h2 {
    color: #4c5262;
    margin-bottom: 50px;
}

.landing-page p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.landing-page button {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: #308fcd;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
    margin-bottom: 20px;
}

.landing-page button:hover {
    background-color: #2980b9;
}

.hidden-email-login {
    position: absolute;
    bottom: 10px;
    left: 10px;
    opacity: 0;
    z-index: 1; /* ensures it's clickable */
}

/* Application page */

.logout-button {
    position: absolute; /* Absolute positioning for the button */
    top: 25px; /* 10px from the top of the container */
    right: 25px; /* 10px from the right side of the container */
    padding: 8px 15px;
    font-size: 14px;
    background-color: #308fcd;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
    outline: none;
}

.logout-button:hover {
    background-color: #2980b9; /* A slightly darker red for hover effect */
}

.applications-page-items .image-container {
    width: 300px;
    height: 120px;
    background: url('./assets/altSolBackground.png') no-repeat center;
    background-size: contain;
    margin: 0 auto 20px; /* center the image horizontally and 20px margin at the bottom */
    margin-top: -100px;
}

.applications-page-items {
    border-radius: 15px; /* Rounded corners */
    max-width: 1400px; /* Maximum width */
    padding: 50px 0; /* Inner vertical spacing */
    margin: 20px auto; /* Vertical spacing and auto margin for horizontal centering */
    transition: transform 0.2s ease; /* Transition for hover effect */
}

/* Style updates for TestEnvironmentToggle component */

div {
    padding: 10px 0; /* Adding padding for all divs for better spacing */
}

label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

input[type='checkbox'] {
    margin-right: 10px;
}

.test-env-toggle {
    display: flex;
    align-items: center;
    padding-top: 0px;
    width: 100%;
    justify-content: center; /* Centers children */
    margin-bottom: 20px;
}

.mode-message {
    font-size: 0.9em;
    color: #444;
    margin-left: 20px; /* Space from the "Live Mode" checkbox */
    max-width: 250px;
    white-space: nowrap;
}

.test-env-toggle p strong {
    font-weight: bold;
}

/* FileInput */

.file-input-wrapper {
    display: flex;
    justify-content: center; /* This will ensure the containers stay centered */
    margin: 0 auto; /* This centers the wrapper on the page */
    padding-left: 130px; /* Adjust this value to shift the wrapper to the right */
    max-width: 70%; /* You can adjust this value to control the combined width of the two containers */
}

.file-input-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* This ensures each container takes up half of the wrapper's width, minus the gap */
}

input[type='file'] {
    margin-left: 10px;
}

.file-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 0.9em;
    font-style: italic;
}

button.create-letters-button {
    margin-top: 10px;
    padding: 10px 20px;
    background: #308fcd;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background 0.2s;
}

/* .create-letters-button[disabled] {
   background-color: #8f8f8f;
   cursor: not-allowed;
} */

button.create-letters-button:hover {
    background: #2980b9;
}

.success,
.error,
.information {
    padding: 8px 12px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Relative positioning to allow absolute positioning of the 'x' */
}

.success span,
.error span,
.information span {
    position: absolute; /* Absolutely position the 'x' */
    top: 8px; /* Adjust as necessary */
    right: 8px; /* Adjust as necessary */
}

.success {
    color: #155724; /* Darker green to increase readability */
    background-color: #d4edda; /* Light green background for better visibility */
    border: 1px solid #c3e6cb; /* Matching green border */
}

.information {
    color: #8a6d3b; /* Darker orange for text readability */
    background-color: #fcf8e3; /* Soft orange background for visibility */
    border: 1px solid #faebcc; /* Matching orange border */
}

.error {
    color: #721c24; /* Darker red to increase readability */
    background-color: #f8d7da; /* Light red background for better visibility */
    border: 1px solid #f5c6cb; /* Matching red border */
}

/* SelectSender Component */

.contact-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: -15px;
}

.contact-input-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
}

.contact-input {
    width: 200px; /* Ensuring consistent width for both input and dropdown */
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
}

button {
    padding: 10px 20px;
    background-color: #308fcd; /* Primary blue color */
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.2s;
    outline: none; /* Remove default outline */
}

button:hover {
    background-color: #2980b9; /* Slightly darker blue on hover */
}

button:active {
    transform: scale(
        1
    ); /* Reset size on active state for a button press effect */
}

.info-text {
    margin-top: -10px; /* Adjust based on your design preference */
    font-size: 0.9em; /* Adjust font size if necessary */
    color: #555; /* Choose a readable color */
}

.info-text a {
    color: #3498db; /* Hyperlink color */
    margin-left: 5px; /* Spacing between text and the link */
    text-decoration: none; /* Remove underline */
}

.info-text a:hover {
    text-decoration: underline; /* Underline on hover for better UX */
}

.feedback-message {
    font-size: 0.85em;
    color: #444;
    position: absolute; /* Makes the message position relative to its parent */
    left: 100%; /* Position it to the right of its parent */
    top: 50%; /* Align it vertically in the middle of its parent */
    transform: translateY(-50%); /* Fine-tune vertical centering */
    margin-left: 20px; /* Space from the "Set Contact" button */
    width: max-content; /* Adjust the width */
    max-width: 250px; /* Set a maximum width if needed */
    white-space: nowrap;
}

/* Download Report component */
.centered-button-container {
    display: flex;
    flex-direction: column; /* This makes the children stack vertically */
    align-items: center; /* This will center the items horizontally */
    gap: 2px; /* Creates a uniform space between the child elements */
    margin-top: 10px; /* Existing margin for spacing */
}
.centered-button-container > button {
    margin-bottom: 10px; /* Adjust as needed */
}

.centered-button-container > * {
    margin-top: -5px;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
